<template>
  <v-expansion-panels
    v-model="panel"
    multiple
  >
    <document-agendas
      :meetings="meetings"
      :voteResults="voteResults"
      :relatedAttachments="relatedAttachments"
      :id="documentId"
    />

    <list-subjects
      v-if="document && document.subjects.length > 0"
      :subjects="document.subjects"
    />

    <document-references
      :refs="refs"
      :id="documentId"
    />
  </v-expansion-panels>
</template>

<script>
import ListSubjects from '@/components/ListSubjects.vue';
import DocumentAgendas from './DocumentAgendas.vue';
import DocumentReferences from './DocumentReferences.vue';

export default {
  name: 'document-detail-sidebar',

  components: {
    DocumentAgendas,
    ListSubjects,
    DocumentReferences,
  },

  props: {
    document: {
      type: Object,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      panel: [0, 1, 2, 3],
    };
  },

  computed: {
    meetings() {
      if (!this.document.related) return [];
      const agendas = [];
      if (this.document.related.meetings && this.document.related.meetings.length > 0) {
        this.document.related.meetings.forEach((item) => {
          item.meeting = true;
          agendas.push(item);
        });
      }
      if (this.document.related.agendaItems && this.document.related.agendaItems.length > 0) {
        this.document.related.agendaItems.forEach((item) => {
          item.agendaItem = true;
          agendas.push(item);
        });
      }
      return agendas;
    },

    voteResults() {
      // Some documents like 'moties' have voteResults
      if (this.document.related
        && this.document.related.dossier
        && this.document.related.dossier.voteResults) {
        return this.document.related.dossier.voteResults;
      }
      return null;
    },

    relatedAttachments() {
      // Document retalted attachments
      const dhAttachments = [];
      if (this.document.related && this.document.related.documents) {
        this.document.related.documents.forEach((item) => {
          dhAttachments.push(item);
        });
      }
      return dhAttachments;
    },

    refs() {
      // EUROPARL refs
      const europarl = [];
      if (this.document && this.document.euReferenceCodes) {
        europarl.push(...this.document.euReferenceCodes);
      }

      // DLRC ref(s)
      const dhapi = [];
      if (this.document.related && this.document.related.dossier) {
        const { dossierId } = this.document.related.dossier;
        if (dossierId) dhapi.push(dossierId);
      }

      // For consistency reasons euRefs should be an object as we do also in other places.
      let result = {};
      if (europarl.length > 0) result = { europarl };
      if (dhapi.length > 0) result = { ...result, dhapi };
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
div.v-expansion-panel[aria-expanded="true"] {
  margin-bottom: -12px;
}
</style>
