<!--
  Component which acts as a container for content with predefined, formatted positions
-->

<template>
  <span>
    <v-btn
      v-if="link"
      @click="shareByWhatsApp"
      icon
      small
      fab
      color="grey"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>

    <v-btn
      v-if="link"
      @click="shareByMail"
      icon
      small
      fab
      color="grey"
    >
      <v-icon>mdi-email-outline</v-icon>
    </v-btn>

    <span
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-menu
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-n4"
            dark
            icon
            small
            v-bind="attrs"
            v-on="on"
            fab
            color="grey"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="sourceUrl"
            @click="goToSourceUrl"
          >
            <v-list-item-title>
              Download
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown && authHasModuleAccess('MC04')"
            @click="addToList()"
          >
            <v-list-item-title>
              + Lijst
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>

    <span v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-if="sourceUrl"
        @click="goToSourceUrl"
        icon
        small
        fab
        color="grey"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>

      <v-btn
        v-if="listModuleSupport && $vuetify.breakpoint.mdAndUp && authHasModuleAccess('MC04')"
        class="ml-2"
        outlined
        color="grey"
        @click="addToList()"
      >
        + Lijst
      </v-btn>
    </span>

  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import { euInstitutionCodeToName } from '@/helpers/groupingHelper';
import urlToSourceName from '@/filters/urlToSourceName';
import ListTimelineItemForm from '@/components/ListTimelineItemForm.vue';
import { showDialog } from '@/helpers/dialogHelper';

export default {
  name: 'DocumentLinks',

  data() {
    return {
      fab: null,
    };
  },

  filters: {
    urlToSourceName,
  },

  props: {
    link: {
      type: String,
      required: false,
    },
    sourceUrl: {
      type: String,
      required: false,
    },
    sourceTitle: {
      type: String,
      required: false,
    },
    documentId: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    docType: {
      type: String,
      required: false,
    },
    listModuleSupport: {
      type: Boolean,
      required: true,
    },
    institution: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
    }),

    computedSourceName() {
      if (this.institution) {
        return euInstitutionCodeToName(this.institution);
      }
      if (this.link) {
        return this.$options.filters.urlToSourceName(this.link).replace('www', '');
      }
      return null;
    },

    dateAsString() {
      return this.$moment(this.date).format('DD-MM-YYYY');
    },
  },

  methods: {
    addToList() {
      this.$gtag.event('document-add-to-list', {
        event_category: 'document',
      });
      showDialog(ListTimelineItemForm, {
        document: {
          id: this.documentId,
          date: this.date,
          type: this.docType,
          sourceUrl: this.link,
        },
        title: this.sourceTitle,
      });
    },

    shareByMail() {
      let ebody = `${this.computedSourceName ? `${this.computedSourceName} - ` : ''}${this.type}\r\n\r\n${this.sourceTitle ? `Titel: ${this.sourceTitle}\r\n\r\n` : ''}${this.dateAsString ? `Datum: ${this.dateAsString}\r\n\r\nAangeboden door https://www.polpo.nl\r\n\r\n` : ''}${this.link ? `Bron: ${this.link}\r\n\r\n` : ''}`;
      ebody = encodeURIComponent(ebody);
      window.open(`mailto:username@example.com?subject=${this.sourceTitle}&body=${ebody}`);
    },

    shareByWhatsApp() {
      let ebody = `${this.link}\r\nAangeboden door https://www.polpo.nl`;
      ebody = encodeURIComponent(ebody);
      window.open(`https://api.whatsapp.com/send?phone=&text=${ebody}`, '_blank');
    },

    // Somehow the url for europarl pdffiles are not downloaded directly but first shown in a separate window.
    // Strange enough this isn't the case with pdfs from the 'Tweede Kamer'.
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
    // POLMONNL-2479
    goToSourceUrl() {
      window.open(this.sourceUrl, '_self');
    },
  },

};
</script>

<style scoped lang="scss">
</style>
