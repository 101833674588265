<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-subheader class="mb-n2 overline">
            Bladwijzers
          </v-subheader>
          <v-card>
            <v-list
              dense
              flat
            >
              <v-list-item-group color="teal">
                <v-list-item
                  @click="scrollTo(0)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-bookmark</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Naar boven</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  class="bookmark"
                  v-for="(bookmark, index) in bookmarks"
                  :key="index"
                  @click="scrollToBookmark($event, bookmark)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-bookmark</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="bookmark.innerHTML.toLowerCase()" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="my-14"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {

  props: {
    bookmarks: {
      type: Array,
      required: true,
    },
  },

  methods: {
    scrollToBookmark(event, bookmark) {
      this.$gtag.event('document-bookmark-clicked', {
        event_category: 'document',
      });

      if (bookmark.classList.contains('pdfHlt')) {
        // New method does not work for IE, only used for pdfView.
        bookmark.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } else {
        const scrollOffset = bookmark.getBoundingClientRect().top // Distance from the element to the top of the current viewport.
          + document.documentElement.scrollTop // Distance from document top to the top of the current viewport.
          - (window.innerHeight / 2); // To make the highligh fall in the middle of the screen
        this.scrollTo(scrollOffset);
      }
    },

    scrollTo(yPosition) {
      if (this.isIE11) {
        window.scrollTo({ top: yPosition, left: 0, behavior: 'smooth' });
      } else {
        this.$vuetify.goTo(yPosition, {});
      }
    },

  },

};
</script>

<style lang="scss" scoped>

</style>
