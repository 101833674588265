<template>
  <v-expansion-panel
    v-if="(meetings && meetings.length > 0)
      || voteResults
      || (relatedAttachments && relatedAttachments.length > 0)
      || (documentRelatedParent && documentRelatedParent.length > 0)"
  >
    <v-expansion-panel-header>
      <span>Documentgegevens</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        v-if="currentMeetings.length > 0 || pastMeetings.length > 0"
        :class="`${currentMeetings.length === 0 ? 'mb-2' : ''}`"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-calendar</v-icon> Op de agenda</span>
      </div>
      <div>
        <div
          v-if="currentMeetings.length > 0"
          class="text-body-2 mb-1"
        >
          <ul class="text-body-2 plain-li text--secondary">
            <li
              v-for="(item, index) in currentMeetings"
              :key="index"
              class="mb-2"
              :class="{ 'mt-2': index === 0 }"
            >
              <span v-if="item.meeting && item.subject">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} ` : '' }}
                  </strong>
                  {{ item.startTime ? `(${item.startTime}${item.endTime ? ` - ${item.endTime}` : ''}` : '' }})
                  {{ item.activityType ? `${item.activityType}` : 'Vergadering' }}
                  {{ item.subject !== item.activityType ? ` - ${item.subject}` : '' }}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
              <span v-if="item.agendaItem && item.title">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} - ` : '' }}
                  </strong>
                  {{ item.docType ? `${item.docType} - ` : 'Agendapunt - ' }}
                  {{this.title}}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div
          class="text-body-2 mb-2"
          v-if="pastMeetings.length > 0"
        >
          <span class="text-body-2 text--secondary">Geweest</span>
          <ul class="text-body-2 plain-li past-list text--secondary">
            <li
              v-for="(item, index) in pastMeetings"
              :key="index"
              class="check mb-1"
            >
              <span v-if="item.meeting && item.subject">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} ` : '' }}
                  </strong>
                  {{ item.startTime ? `(${item.startTime}${item.endTime ? ` - ${item.endTime}` : ''}` : '' }})
                  {{ item.activityType ? `${item.activityType}` : 'Vergadering' }}
                  {{ item.subject !== item.activityType ? ` - ${item.subject}` : '' }}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
              </span>
              <span v-if="item.agendaItem && item.title">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  <strong>
                    {{ item.date ? `${item.date} - ` : '' }}
                  </strong>
                  {{ item.docType ? `${item.docType} - ` : 'Agendapunt - ' }}
                  {{this.title}}{{ item.documentType ? `(${item.documentType})` : '' }}&nbsp;&#187;
                </a>
            </span>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="documentRelatedParent && documentRelatedParent.length > 0"
        class="text-body-2 mt-4 mb-4"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-attachment</v-icon> Kamerstuk</span>
        <ul class="text-body-2 plain-li text--secondary">
          <li
            v-for="(item, index) in documentRelatedParent"
            :key="index"
            class="mb-1"
          >
            <a
              :href="item.url"
              target="_blank"
            >
              <strong>
                {{ item.publicationDate ? `${$moment(item.publicationDate).format('DD-MM-YYYY')} - ` : '' }}
              </strong>
              {{ item.title }} {{ item.dossierId ? `(${item.dossierId})` : item.entityCode ? `(${item.entityCode})` : '' }}&nbsp;&#187;
            </a>
          </li>
        </ul>
      </div>

      <div
        v-if="relatedAttachments.length > 0"
        class="text-body-2 mt-4 mb-4"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-attachment</v-icon> Bijlage(n)</span>
        <ul class="text-body-2 plain-li text--secondary">
          <li
            v-for="(item, index) in relatedAttachments"
            :key="index"
            class="mb-1"
          >
            <a
              :href="item.url"
              target="_blank"
            >
              <strong>
                {{ item.date ? `${item.date} - ` : '' }}
              </strong>
              {{ item.title }} {{ item.documentId ? `(${item.documentId})` : '' }}&nbsp;&#187;
            </a>
          </li>
        </ul>
      </div>

      <div
        v-if="voteResults"
        class="text-body-2 mt-6"
      >
        <v-icon color="grey darken-1">mdi-gavel</v-icon> Stemmingsuitslag
        <p><strong>{{ voteResults }}</strong></p>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import moment from 'moment';

import { mapGetters } from 'vuex';

export default {

  props: {
    meetings: {
      type: Array,
      required: false,
    },
    voteResults: {
      type: String,
      required: false,
    },
    relatedAttachments: {
      type: Array,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      documentRelatedParent: 'documentRelatedParent',
    }),

    currentMeetings() {
      const meetings = [];
      this.meetings.forEach((meeting) => {
        // We are only interested in dates not time
        const convertedNlDate = new Date(moment(meeting.date, 'DD-MM-YYYY')).toDateString();
        const now = new Date().toDateString();
        if (moment(convertedNlDate).isSameOrAfter(now)) {
          meetings.push(meeting);
        }
      });
      return meetings;
    },

    pastMeetings() {
      const meetings = [];
      this.meetings.forEach((meeting) => {
        // We are only interested in dates not time
        const convertedNlDate = new Date(moment(meeting.date, 'DD-MM-YYYY')).toDateString();
        const now = new Date().toDateString();
        if (moment(convertedNlDate).isBefore(now)) {
          meetings.push(meeting);
        }
      });
      return meetings;
    },
  },

  methods: {
  },

  mounted() {
    if (this.id) {
      this.$store.dispatch('getRelatedParent', {
        id: this.id,
      });
    }
  },

};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.past-list{
  list-style-type: none;
  .check:before {
    content: '✓';
    margin-left: -12px;
    margin-right: 3px;
  }
}
</style>
