<template>
  <div>
    <v-subheader
      class="text-subtitle-1 text--primary"
      style="flex-wrap: wrap;"
    >
      <div
        style="font-size: 1.10rem; min-width: 150px;"
        class="flex-grow-0 flex-shrink-1 text-truncate"
        v-if="mounted && documentTypesMixinReady"
      >
        {{ getDocumentTypeLocalization(document.type) }}
      </div>
      <v-spacer></v-spacer>
      <span class="options mt-n1 pb-1">
        <span
          class="pdfToggle"
          v-if="pdfViewerAvailable && $vuetify.breakpoint.mdAndUp"
        >
          <span class="txt">TXT</span>
          <v-switch
            class="pdfSwitch"
            small
            color="grey"
            @change="toggleShowPDF"
            :input-value="showPDF"
          ></v-switch>
          <span>PDF</span>
        </span>

        <div class="d-inline-flex justify-end flex-grow-0 flex-shrink-0">
          <span v-if="pdfViewerAvailable && $vuetify.breakpoint.smAndDown">
            <v-btn
              @click="toggle"
              icon
              small
              fab
              color="grey"
            >
              <v-icon v-if="isActivePdf">mdi-file-document</v-icon>
              <v-icon v-else>mdi-file-pdf-box</v-icon>
            </v-btn>
          </span>

          <document-links
            :link="link || document.url"
            :sourceUrl="getPdfSourceUrl()"
            :sourceTitle="document.title"
            :documentId="documentId"
            :date="document.date"
            :type="getDocumentTypeLocalization(document.type)"
            :docType="document.type"
            :listModuleSupport="true"
            :institution="document.institution"
          />
        </div>
      </span>
    </v-subheader>

    <v-card>
      <v-card-title
        v-if="editedTitle"
      >
        {{ editedTitle | stripHtml }}
      </v-card-title>

      <v-card-subtitle class="font-weight-medium text--primary pt-2" v-if="document">
        <span class="subTitlePart">Origineel: <a
          v-if="documentUrl()"
          class="blue--text text--darken-2"
          @click="trackOpenSource(documentUrl())"
          :href=documentUrl()
          target="_blank"
        >{{ document.url | urlToSourceName | replace('www.', '') }}</a></span>
        <span class="subTitlePart">Datum: {{ formatDate(this.document.date) }}</span>
        <span
          v-if="this.document.availableOnSourceSince"
          class="subTitlePart"
        >
          Geüpload door bron: {{ formatDate(this.document.availableOnSourceSince) }}
        </span>
      </v-card-subtitle>

      <v-card-text
        v-if="!showPDF"
      >
        <document-text
          v-if="document && document.text"
          id="documentText"
          ref="documentText"
          :isRawHTML="document.rawDocumentContent ? true : false"
          :text="document.rawDocumentContent ? document.rawDocumentContent : document.text"
          :link="link || document.url"
          :rssDocument="rssDocument"
        />
      </v-card-text>

    </v-card>

    <!-- PDF.js viewer -->
    <pdf-viewer
      v-if="showPDF && getPdfSourceUrl()"
      :pdf="pdf"
      :keywords="document.keywords"
      @pdfPageReady="reemit"
      @pdfRender="$emit('pdfRender')"
      @pdfRendered="$emit('pdfRendered')"
      :expandedView="pdfExpandedView"
      @toggleExpandedView="$emit('togglePdfExpandedView')"
    ></pdf-viewer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { showDialog } from '@/helpers/dialogHelper';
import urlToSourceName from '@/filters/urlToSourceName';
import replace from '@/filters/replace';
import stripHtml from '@/filters/stripHtml';
import fragDocufy from '@/filters/fragDocufy';
import DocumentLinks from '@/components/DocumentLinks.vue';
import DocumentText from '@/components/DocumentText.vue';
import PdfViewer from '@/components/PDFViewer.vue';
import DocumentTypesMixin from '@/mixins/DocumentTypesMixin';
import EventBus from '../event-bus';
import DocumentSwitchToTextView from './DocumentSwitchToTextView.vue';


export default {
  components: {
    DocumentLinks,
    DocumentText,
    PdfViewer,
  },

  filters: {
    urlToSourceName,
    replace,
    fragDocufy,
    stripHtml,
  },

  mixins: [DocumentTypesMixin],

  props: {
    document: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    rssDocument: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdf: {
      type: ArrayBuffer,
      required: false,
    },
    showPDF: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdfMissing: {
      type: Boolean,
      required: true,
    },
    pdfExpandedView: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      mounted: false,
      isActivePdf: true,
    };
  },

  // mounted() { this.mounted = true; },
  mounted() {
    const vm = this;
    // Intercept key 'Ctrl-F' and Cmd-F
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.metaKey && e.keyCode === 70))) {
        if (vm.showPDF && vm.getPdfSourceUrl()) {
          // e.preventDefault();
          vm.initSwitchToDocumentView();
        }
      }
    });
    this.mounted = true;
  },

  unmounted() {
    const vm = this;
    window.removeEventListener('keydown', (e) => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)
        || (e.metaKey && e.keyCode === 70))) {
        vm.initSwitchToDocumentView();
      }
    });
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      isIE11: 'isIE11',
    }),

    documentId() {
      return this.$route.params.documentId;
    },

    editedTitle() {
      if (this.document.title && this.document.fragDocu) {
        return `${this.document.title} ${this.$options.filters.fragDocufy(this.document.fragDocu) || ''}`;
      }
      return this.document.title;
    },

    pdfViewerAvailable() {
      return (!this.isIE11)
        && !this.pdfMissing
        && this.document.pdfInfo
        && this.document.pdfInfo.pdfSourceUrl;
    },
  },

  methods: {
    formatDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    toggle() {
      this.toggleShowPDF(!this.isActivePdf);
    },

    reemit(...args) {
      this.$emit('pdfPageReady', ...args);
    },

    documentUrl() {
      // Convert the europarl link to https because chrome won't download document over http.
      if (/http:\/\/(www\.)?europarl\.europa\.eu/.test(this.document.url)) {
        return this.document.url.replace('http://', 'https://');
      }

      const { related } = this.document;
      if (
        ['PC', 'CC'].includes(this.document.type.split('-')[0])
        && (related && related.dossier && related.dossier.url)
      ) return related.dossier.url;

      return this.document.url;
    },

    trackOpenSource(url) {
      this.$gtag.event('document-source-external', {
        event_category: 'document',
        event_label: url,
      });
    },

    getDocumentTypeLocalization(documentType) {
      const loc = this.getLocalizationForDocTypeId(documentType);
      return typeof loc === 'string' ? loc : loc.singular;
    },

    toggleShowPDF(value) {
      this.isActivePdf = !this.isActivePdf;
      if (this.pdfExpandedView) this.$emit('togglePdfExpandedView');
      this.$emit('toggleShowPDF', value);
      if (!value) {
        EventBus.$off('switchToTextView');
      }
    },

    getPdfSourceUrl() {
      const { pdfInfo } = this.document;
      if (!pdfInfo) return null;
      const { pdfSourceUrl } = pdfInfo;
      return pdfSourceUrl;
    },

    initSwitchToDocumentView() {
      if (this.isActivePdf && this.getPdfSourceUrl()) {
        showDialog(DocumentSwitchToTextView);
        EventBus.$on('switchToTextView', this.toggleShowPDF);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .v-subheader {
    height: auto;
  }
  .subTitlePart {
    margin-right: 1em;
  }

  .options {
    display: flex;
    flex-wrap: wrap;

    .pdfToggle {
      display: flex;
      align-items: center;

      font-size: 0.8em;
      margin: 0 1em;

      padding-right: 1.5em;
      border-right: thin solid #9e9e9e;

      span {
        font-weight: 500;

        &.txt {
          margin-right: 0.5em;
        }
      }

      .pdfSwitch {
        margin: 0;
        padding: 0;

        ::v-deep {
          .v-input__slot {
            margin-bottom: 0;
          }
          .v-messages {
            display: none;
          }
        }
      }
    }
  }
</style>
