import { render, staticRenderFns } from "./DocumentLinks.vue?vue&type=template&id=1e28643a&scoped=true&"
import script from "./DocumentLinks.vue?vue&type=script&lang=js&"
export * from "./DocumentLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e28643a",
  null
  
)

export default component.exports