<template>
  <div class="document">
    <base-layout
      :bookmark-button="true"
      :filter-button="false"
      :back-button="$vuetify.breakpoint.mdAndDown"
      :show-drawer-right="false"
    >
      <template v-slot:drawer-left>
        <document-pdf-bookmarks
          v-if="showPDF"
          :pdfBookmarks="pdfBookmarks"
          :pdfBookmarksLoading="pdfBookmarksLoading"
          :keywords="document.keywords || []"
        />
        <document-bookmarks
          v-if="!showPDF"
          :bookmarks="bookmarks"
        />
      </template>

      <template v-slot:identity>
        <a
          :href="`/${dashboard}/dashboard`"
          class="d-flex align-start"
        >
          <img
            style="cursor: pointer;"
            src="../assets/logo-transparant-groot.png"
            alt="Polpo - Politieke monitoring"
            height="42"
          >
        </a>
      </template>

      <template v-slot:main-content>
        <div>
          <v-container fluid>
            <v-row dense>

              <!-- left -->
              <v-col
                cols="12"
                :md="rightColumnOpen ? '8' : '12'"
                :xl="rightColumnOpen ? '8' : '12'"
                class="pr-2"
                v-if="document"
              >
                <document-layout
                  :document="document"
                  :link="link"
                  :rssDocument="rssDocument"
                  :pdf="pdf"
                  :showPDF="showPDF"
                  @toggleShowPDF="toggleShowPDF"
                  @pdfRender="pdfRender"
                  @pdfRendered="pdfRendered"
                  @pdfPageReady="pdfPageReady"
                  :pdfMissing="pdfMissing"
                  :pdfExpandedView="!rightColumnOpen"
                  @togglePdfExpandedView="rightColumnOpen = !rightColumnOpen"
                />
              </v-col>

              <!-- right -->
              <v-col
                v-if="document && rightColumnOpen"
                cols="12"
                md="4"
                class="d-none d-md-block"
              >
                <document-detail-sidebar
                  v-if="dashboard === 'nl' && !nlLrDocument"
                  :document="document"
                  :documentId="documentId"
                  :nlLrDocument="nlLrDocument"
                ></document-detail-sidebar>
                <europarl-detail-sidebar
                  v-if="dashboard === 'eu' && !nlLrDocument"
                  :document="document"
                  :documentId="documentId"
                ></europarl-detail-sidebar>
                <local-regional-detail-sidebar
                  v-if="dashboard === 'nlLr'"
                  :document="document"
                  :documentId="documentId"
                ></local-regional-detail-sidebar>
              </v-col>
            </v-row>

          </v-container>

        </div>
      </template>

    </base-layout>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLayout from '@/components/BaseLayout.vue';
import DocumentBookmarks from '@/components/DocumentBookmarks.vue';
import DocumentPdfBookmarks from '@/components/DocumentPDFBookmarks.vue';
import DocumentLayout from '@/components/DocumentLayout.vue';
import DocumentDetailSidebar from '@/modules/document/components/DocumentDetailSidebar.vue';
import EuroparlDetailSidebar from '@/modules/europarl/components/EuroparlDetailSidebar.vue';
import LocalRegionalDetailSidebar from '@/modules/local-regional/components/LocalRegionalDetailSidebar.vue';

import Config from '@/config';
import EventBus from '../event-bus';

export default {
  name: 'document-detail',

  components: {
    BaseLayout,
    DocumentBookmarks,
    DocumentPdfBookmarks,
    DocumentLayout,
    DocumentDetailSidebar,
    EuroparlDetailSidebar,
    LocalRegionalDetailSidebar,
  },

  beforeCreate() {
    this.dashboard = this.$route.params.env || 'nl';
    if (this.$route.params.env === 'eu') this.dashboard = 'eu';
    else if (this.$route.params.env === 'nl-lr') this.dashboard = 'nlLr';
    else this.dashboard = 'nl'; // Set default dashboard to nl (for legacy reasons)
  },

  created() {
    // Only continue if polyfill is actually needed
    if (!('scrollBehavior' in document.documentElement.style)) {
      // We are dealing with a very old browser
      this.polyfillNeeded = true;
      // Wait until the Polyfill has been loaded
      import('smoothscroll-polyfill')
        .then((smoothscroll) => {
          smoothscroll.polyfill();
        });
    }
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: `/document/${this.documentId}`,
    });

    await this.$store.dispatch('getFilterTypesForUser');

    this.$nextTick(() => {
      if (this.documentId) {
        this.$store
          .dispatch(`${this.dashboard}GetDocumentById`, {
            id: this.documentId,
            highlightWord: this.highlightWord,
          })
          .then((res) => {
            // Calculate the document offset only when the document data is available
            // we have to wait till the screen is drawn to get accurate offset
            const { type } = res.data;
            if (type) {
              if (type.startsWith('SB-') || type.startsWith('MD-')) {
                this.link = res.data.url;
                this.rssDocument = true;
              }

              if (type.startsWith('CC') || type.startsWith('PC')) {
                this.nlLrDocument = true;
              }
            }

            setTimeout(() => {
              this.$store.dispatch('setDocumentId', null);
              this.$store.dispatch('setHighlightedWord', null);
              this.$store.dispatch('setIsEmailLink', null);
            }, 400);
          });
      } else {
        this.$router.push({ name: 'dashboard' }).catch(() => {});
      }
    });
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const that = vm;
      if (from.fullPath === '/') {
        that.$store.dispatch('setReferrer', false);
      }
    });
  },

  data() {
    return {
      rightColumnOpen: true,
      polyfillNeeded: false,
      link: '',
      rssDocument: false,
      nlLrDocument: false,

      // For TXT view:
      bookmarks: [],

      // For PDF View:
      pdfExists: false,
      showPDF: false,
      pdfBookmarks: [],
      pdfBookmarksLoading: false,

    };
  },

  computed: {
    // These have to be a functions instead of being added via mapGetters
    // because of the availabilty of the `this.env` param.
    pdf() {
      return this.$store.getters[`${this.dashboard}Pdf`];
    },
    pdfMissing() {
      return this.$store.getters[`${this.dashboard}PdfMissing`];
    },
    document() {
      return this.$store.getters[`${this.dashboard}Document`];
    },

    ...mapGetters({
      isIE11: 'isIE11',
    }),

    documentId() {
      return this.$route.params.documentId;
    },

    highlightWord() {
      return this.$route.params.highlightWord;
    },
  },

  methods: {
    async setBookmarks() {
      // Next tick to wait for document to render
      this.$nextTick(() => {
        const allBookmarksElements = document.querySelectorAll('#documentText .hlt1');
        this.bookmarks = this.reduceBookmarks(Array.from(allBookmarksElements));
      });
    },

    pdfRender() {
      this.pdfBookmarksLoading = true;
    },

    pdfPageReady(pageNum, pageElem, keywords) {
      this.$set(this.pdfBookmarks, pageNum, {
        pageNum,
        pageElem,
        keywords,
      });
    },

    pdfRendered() {
      this.pdfBookmarksLoading = false;
    },

    toggleShowPDF(value) {
      this.showPDF = value;
      if (!value) EventBus.$off('switchToTextView');
    },

    reduceBookmarks(bookmarkElements) {
      // Note: When we zoom the distance in pixels between
      // keywords changes which causes some bookmarks to merge
      // or split. So the set of bookmarks is not consistant.

      if (!bookmarkElements || bookmarkElements.length < 1) return [];
      const firstBookmark = bookmarkElements[0];
      let previousKeyword = firstBookmark.innerText.toLowerCase();
      let previousOffsetTop = this.getElemOffsetTop(firstBookmark);
      const reducedBookmarks = [firstBookmark];

      bookmarkElements.slice(1).forEach((bookmark) => {
        const keyword = bookmark.innerHTML.toLowerCase();
        const offsetTop = this.getElemOffsetTop(bookmark);

        if (
          previousKeyword !== keyword // Don't merge different keywords
          || (offsetTop - previousOffsetTop) > Config.minimalBookmarkDistance // Don't merge keywords too far apart
        ) {
          // Add as seperate bookmark
          reducedBookmarks.push(bookmark);
          previousKeyword = keyword;
          previousOffsetTop = offsetTop;
        }
      });

      return reducedBookmarks;
    },

    getElemOffsetTop(elem) {
      return elem.getBoundingClientRect().top // Distance from the element to the top of the current viewport.
        + document.documentElement.scrollTop; // Distance from document top to the top of the current viewport.
    },
  },

  watch: {
    pdfMissing(missing) {
      if (missing) this.showPDF = false;
    },

    document() {
      this.showPDF = !!((!this.isIE11)
        && this.document
        && this.document.pdfInfo
        && this.document.pdfInfo.pdfSourceUrl);

      if (this.showPDF) {
        this.pdfBookmarks = {}; // clear pdfBookmarks
      } else {
        this.setBookmarks();
      }

      this.$nextTick(() => {
        if (this.document.pdfInfo && this.document.pdfInfo.pdfExists) {
          this.$store
            .dispatch(`${this.dashboard}GetPdf`, {
              docType: this.document.type,
              docId: this.documentId,
            })
            .then(() => {
              this.pdfExists = true;
            });
        }
      });
    },

    showPDF(showPDF) {
      if (showPDF) {
        this.pdfBookmarks = {}; // clear pdfBookmarks
      } else {
        this.setBookmarks();
      }
    },
  },

};
</script>
