<template>
  <v-expansion-panel
    class=""
    v-if="hasReferences()"
  >
    <v-expansion-panel-header>Gerelateerde documenten (bèta)</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-timeline
        v-if="items"
        :reverse="false"
        dense
        align-top
      >
        <v-timeline-item
          v-for="item in items.data"
          :key="item.id"
          :color="codeToColor((item.type
            && (item.type.startsWith('EUROPARL-') || item.type.startsWith('EUROCOM-')))
            ? item.institution
            : item.type)"
          :icon="`${(id && id === item.id) ? 'mdi-disc' : ''}`"
          :small="(id && id !== item.id)"
        >
          <v-card
            class="elevation-2"
            @click.stop.prevent="goToUrlConditionally(item.type, item.id)"
          >
            <v-card-title class="text-subtitle-2 mb-n2">
              {{ formattedDate(item.publicationDate) }}
            </v-card-title>
            <v-card-text class="mt-n2">
              {{ item.title | stripHtml }}
              <span v-if="item.fragDocu"> {{ item.fragDocu | fragDocufy }}</span>
            </v-card-text>
            <v-card-text class="text-caption mt-n5">
              {{ getCleanedRef(item) }} <span v-if="getProcedureNumber(item)">
                <span class="font-weight-bold">en</span>
                {{ getProcedureNumber(item) }}
              </span>
               - <span class="font-weight-bold">{{ codeToName(item.institution) }}
                 <span v-if="item.url">
                   {{ item.url | urlToSourceName }}
                 </span>
              </span>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import typeToColor from '@/helpers/colorHelper';
import cleanReferenceNumber from '@/filters/cleanReferenceNumber';
import urlToSourceName from '@/filters/urlToSourceName';
import fragDocufy from '@/filters/fragDocufy';
import stripHtml from '@/filters/stripHtml';

export default {

  props: {
    refs: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },

  filters: {
    cleanReferenceNumber,
    urlToSourceName,
    fragDocufy,
    stripHtml,
  },

  computed: {
    ...mapGetters({
      items: 'europarlDocumentRelated',
    }),

    cleanedRefs() {
      if (!this.refs || typeof this.refs !== 'object') return [];
      let cleanRefs = [];
      Object.values(this.refs).forEach((refType) => {
        cleanRefs = cleanRefs.concat(refType.map(((ref) => cleanReferenceNumber(ref))));
      });
      return cleanRefs;
    },
  },

  methods: {
    codeToColor(code) {
      return typeToColor(code);
    },

    hasReferences() {
      // References include the item itself.
      // We only show refs if OTHER references are found.
      if (this.items && this.items.data.length > 1) {
        return true;
      }
      return false;
    },

    // TODO: Have to map these items from an decent API call
    codeToName(code) {
      if (code === 'EC') {
        return 'Europese Commissie';
      }
      if (code === 'EP') {
        return 'Europees Parlement';
      }
      return '';
    },

    formattedDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    getCleanedRef(item) {
      // Expected case Europarl documents:
      if (item.reference) return cleanReferenceNumber(item.reference);

      // Expected case Tweede en Eerst Kamer documents:
      // Finds a reference the document has in common with the main document.
      // (metadata mentions only)
      // This is a bit hacky, and should be replaced when we start
      // returning the matched references from backend.
      let matchedRef;
      if (item.metadata && item.metadata.mentions && item.metadata.mentions.length > 0) {
        const matchedMention = item.metadata.mentions.find((mention) => this
          .cleanedRefs.includes(mention.term));
        matchedRef = matchedMention ? matchedMention.term : null;
      }
      return matchedRef;
    },

    getProcedureNumber(item) {
      const procedures = [];
      if (item.relations) {
        item.relations.forEach((relation) => {
          if (relation.type === 'PROCEDURE') {
            procedures.push(relation.code);
          }
        });
      }
      return procedures.toString().replace(',', ', ');
    },

    actionUrl(type, id) {
      const destination = type.startsWith('EUROPARL-') || type.startsWith('EUROCOM-') ? '/eu/document' : '/document';
      return `${destination}/${id}`;
    },

    goToUrlConditionally(type, id) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: this.actionUrl(type, id) });
      } else {
        window.open(this.actionUrl(type, id), '_blank');
      }
    },
  },

  mounted() {
    this.$store.dispatch('europarlGetRelated', {
      refs: this.refs,
    });
  },

};
</script>

 <style lang="scss" scoped>

 </style>
