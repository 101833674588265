<template>
  <v-expansion-panel
    v-if="(meetings && meetings.length > 0) || voteResults"
    class=""
  >
    <v-expansion-panel-header>
      <span>Documentgegevens</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div
        v-if="meetings && meetings.length > 0"
      >
        <span class="text-body-2 font-weight-bold text--secondary"><v-icon color="grey darken-1">mdi-calendar</v-icon> Op de agenda</span>
      </div>
      <div
        v-if="meetings && meetings.length > 0"
        class="text-body-2 mb-1"
      >
        <ul class="text-body-2 plain-li text--secondary">
          <li
            v-for="(item, index) in meetings"
            :key="index"
            class="mb-2"
            :class="{ 'mt-2': index === 0 }"
          >
            <strong>{{ item.meeting ? 'Vergadering: ' : 'Agendapunt: ' }}</strong>
            <a
              :href="item.url"
              target="_blank"
            >
              {{ item.agendaItem ? item.title : item.subject }}&nbsp;&#187;
            </a>
          </li>
        </ul>
      </div>
      <div
        v-if="voteResults"
        class="text-body-2 font-weight-bold text--secondary mt-6"
      >
        <v-icon color="grey darken-1">mdi-gavel</v-icon> Stemmingsuitslag
        <ul class="text-body-2 plain-li text--secondary">
          <li class="mb-1"><strong>{{ voteResults }}</strong></li>
        </ul>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {

  props: {
    meetings: {
      type: Array,
      required: false,
    },
    voteResults: {
      type: String,
      required: false,
    },
  },

  filters: {

  },

  computed: {

  },

  methods: {

  },

  mounted() {

  },

};
</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
</style>
