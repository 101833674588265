<template>
  <v-expansion-panel
    v-if="hasReferences()"
  >
    <v-expansion-panel-header>Gerelateerde documenten</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-timeline
        v-if="items"
        :reverse="false"
        dense
        align-top
      >
        <v-timeline-item
          v-for="item in items.data.slice(0, size)"
          :key="item.id"
          :color="codeToColor((item.type
            && (item.type.startsWith('EUROPARL-') || item.type.startsWith('EUROCOM-')))
            ? item.institution
            : item.type)"
          :icon="`${(id && id === item.id) ? 'mdi-disc' : ''}`"
          :small="(id && id !== item.id)"
        >

          <v-card
            class="elevation-2"
            @click.stop.prevent="(item.type && item.type.startsWith('DH-')) ? externalUrl(item.url) : goToUrlConditionally(item.type, item.id)"
          >
            <v-card-title class="text-subtitle-2 mb-n2">
              {{ formattedDate(item.publicationDate) }}
            </v-card-title>
            <v-card-text class="mt-n2">
              {{ item.title | stripHtml }}<span v-if="item.fragDocu"> {{ item.fragDocu | fragDocufy }}</span>
            </v-card-text>
            <v-card-text class="text-caption mt-n5 font-weight-bold">
              {{ getCleanedRef(item) }}
                <span v-if="getProcedureNumber(item)"><span class="font-weight-bold">en</span>{{ getProcedureNumber(item) }}</span>
                <span v-if="item.related && item.related.dossier.dossierId"><span class="font-weight-bold">{{ item.related.dossier.dossierId }} - </span></span>
                <span v-if="item.institution" class="font-weight-bold"> - {{ codeToName(item.institution) }}</span>
                <span v-if="item.url"> <!-- newline here required for space between elems -->
                  {{ item.url | urlToSourceName }}
                </span>
                <span v-if="item.type && item.type.startsWith('DH-')" class="font-weight-bold"> - {{ item.documentType }}</span>
            </v-card-text>
          </v-card>

        </v-timeline-item>
        <v-btn
          v-if="!(size >= items.data.length)"
          @click.stop="showMore()"
        >
          Toon meer
        </v-btn>
      </v-timeline>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { euInstitutionCodeToName } from '@/helpers/groupingHelper';
import typeToColor from '@/helpers/colorHelper';

import { mapGetters } from 'vuex';
import cleanReferenceNumber from '@/filters/cleanReferenceNumber';
import urlToSourceName from '@/filters/urlToSourceName';
import fragDocufy from '@/filters/fragDocufy';
import stripHtml from '@/filters/stripHtml';
import { showDialog } from '@/helpers/dialogHelper';
import EuroparlTeaserDialog from './EuroparlTeaserDialog.vue';
import DutchSenateTeaserDialog from './DutchSenateTeaserDialog.vue';


export default {

  props: {
    refs: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      size: 50,
    };
  },

  filters: {
    cleanReferenceNumber,
    urlToSourceName,
    fragDocufy,
    stripHtml,
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      items: 'documentRelated',
    }),

    cleanedRefs() {
      if (!this.refs || typeof this.refs !== 'object') return [];
      let cleanRefs = [];
      Object.values(this.refs).forEach((refType) => {
        cleanRefs = cleanRefs.concat(refType.map(((ref) => cleanReferenceNumber(ref))));
      });
      return cleanRefs;
    },
  },

  methods: {
    showMore() {
      this.size += 50;
    },

    codeToColor(code) {
      return typeToColor(code);
    },

    hasReferences() {
      // References include the item itself.
      // We only show refs if OTHER references are found.
      if (this.items && this.items.data.length > 1) {
        return true;
      }
      return false;
    },

    codeToName(institutionCode) {
      return euInstitutionCodeToName(institutionCode);
    },

    formattedDate(date) {
      return this.$moment(date).format('DD-MM-YYYY');
    },

    getCleanedRef(item) {
      // Expected case Europarl documents:
      if (item.reference) return cleanReferenceNumber(item.reference);

      // Expected case Tweede en Eerst Kamer documents:
      // Finds a reference the document has in common with the main document.
      // (metadata mentions only)
      // This is a bit hacky, and should be replaced when we start
      // returning the matched references from backend.
      let matchedRef;
      if (item.metadata && item.metadata.mentions && item.metadata.mentions.length > 0) {
        const matchedMention = item.metadata.mentions.find((mention) => this
          .cleanedRefs.includes(mention.term));
        matchedRef = matchedMention ? matchedMention.term : null;
      }
      return matchedRef;
    },

    getProcedureNumber(item) {
      const procedures = [];
      if (item.relations) {
        item.relations.forEach((relation) => {
          if (relation.type === 'PROCEDURE') {
            procedures.push(relation.code);
          }
        });
      }
      return procedures.toString().replace(',', ', ');
    },

    externalUrl(url) {
      window.open(url, '_blank');
    },

    actionUrl(type, id) {
      const destination = type.startsWith('EUROPARL-') || type.startsWith('EUROCOM-') ? '/eu/document' : '/document';
      return `${destination}/${id}`;
    },

    goToUrlConditionally(type, id) {
      if (
        (type.startsWith('EUROPARL-') || type.startsWith('EUROCOM-'))
        && this.authHasModuleAccess('MC07') === false
      ) {
        showDialog(EuroparlTeaserDialog);
      } else if (type.startsWith('DS-') && this.authHasModuleAccess('MC02') === false) {
        showDialog(DutchSenateTeaserDialog);
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: this.actionUrl(type, id) });
      } else {
        window.open(this.actionUrl(type, id), '_blank');
      }
    },
  },

  mounted() {
    if (this.refs && Object.keys(this.refs).length > 0) {
      this.$store.dispatch('getRelated', {
        refs: this.refs,
      });
    }
  },

};
</script>

 <style lang="scss" scoped>
.v-card--link:before {
  background: none;
}
 </style>
